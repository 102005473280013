.purchase-history {
  padding-bottom: 30px;
  padding-top: 30px;

  .purchase-history-aside {
    margin-right: 30px;
    width: 200px;

    p {
      padding: 10px;
      &.active {
        background-color: #eee;
        font-weight: 700;
      }
    }
  }

  .purchase-history-main {
    flex: 1 1;

    .order-item {
      border-top: 1px solid #ddd;
      display: flex;
      padding: 20px 0 10px;

      .order-item-details {
        flex: 1 1;
      }

      .order-item-cost {
        width: 180px;
      }

      .order-item-actions {
        width: 250px;

         > div {
           display: flex;
           margin-bottom: 10px;

           a,
           button {
             text-align: center;
             width: 100%;
           }
         }
      }
    }
  }

  .accept-beed-modal {
    .accept-beed-animation {
      justify-content: space-between;

      .beed-recipient {
        background-color: $white;
        border: 1px solid #ddd;
        padding: 10px;
      }

      .animation-token {
        opacity: 0;
        background-color: $gold;
        border-radius: 5px;
        color: $white;
        left: 115px;
        padding: 5px;
        position: absolute;
        top: 75px;
        transition: opacity 0.1s;
        transition: transform 3s;

        &.transforming {
          opacity: 1;
          transform: translateX(135px);
        }
      }
    }
  }
}
