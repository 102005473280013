  // KEEP THIS
  // .auctions-filter-container {
  //   background-color: #eee;
  //
  //   .auctions-filter-list {
  //     display: flex;
  //     margin: auto;
  //     padding: 10px;
  //     width: 800px;
  //
  //     .auctions-filter {
  //       align-items: center;
  //       display: flex;
  //
  //       .auctions-filter-label {
  //         margin-right: 10px;
  //       }
  //
  //       .toggle-filter {
  //         background-color: $white;
  //         border-radius: 5px;
  //         padding: 7px 7px;
  //
  //         span {
  //           border-radius: 5px;
  //           cursor: pointer;
  //           padding: 5px 15px;
  //
  //           &.active {
  //             background-color: $gold; color: $white;
  //             cursor: default;
  //             pointer-events: none;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

.auctions-index-header {
  border-bottom: 1px solid #ababab;
  padding: 10px 0;
}

.auctions-list-grid {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px 0;

  @media only screen and (max-width: $bp-lg) {
    grid-gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (max-width: $bp-md) {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }

  .auction-grid-item {
    padding: 10px 0;

    // .auction-item-img-container {
    //   height: 0;
    //   width: 100%;
    //   padding-bottom: 100%;
    // }

    .auction-item-content {
      padding: 10px 0;

      .auction-item-bids {
        padding: 10px 0;

        .bid-type {
          .flex-container { align-items: center; }
        }
      }
    }
  }
}

.auctions-list-list {
  padding: 10px 0 20px;

  .auction-list-item {
    display: flex;
    padding: 20px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #303030;
    }

    > a {
      width: 15%;
    }

    .auction-item-content-bids-actions {
      flex: 1 1;
      padding-left: 15px;

      .auction-item-content {
        flex: 1 1;
        padding: 0 5px;
      }

      .auction-item-bids {
        padding: 0 5px;
        width: 25%;
        .bid-type .flex-container { align-items: center; }
      }

      .auction-item-actions {
        padding: 0 5px;
        width: 20%;

        a, button {
          display: block;
          text-align: center;
        }
      }
    }

    @media only screen and (max-width: $bp-md) {
      > a { width: 40%; }
      .auction-item-content-bids-actions {
        .auction-item-bids {
          margin-top: 10px;
          width: 100%;
        }

        .auction-item-actions {
          margin-top: 10px;
          width: 100%;
          a, button { display: inline-block; }
        }
      }
    }

    @media only screen and (max-width: $bp-sm) {
      > a { width: 25%; }
    }
  }
}

.seller-items {
  .seller-header {
    align-items: center;
    background-color: $green;
    display: flex;
    margin-bottom: 20px;
    padding: 15px;

    h1 {
      flex: 1 1;
      margin-left: 10px;
    }
  }
}
