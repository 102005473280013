.loading-animation {
  display: flex;
  &.centered {
    justify-content: center;
  }
}

.status-label {
  background-color: $green;
  border-radius: 5px;
  color: $white;
  padding: 5px;
}

.status-label-sm {
  background-color: $green;
  border-radius: 3px;
  color: $white;
  font-size: 16px;
  line-height: 20px;
  padding: 3px;
}

.img-container {
  background-color: #ddd;
  border-radius: 3px;
  display: flex;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  &.small-img-container {
    padding-bottom: 20%;
    width: 20%;
  }

  img {
    object-fit: contain;
    position: absolute;
  }
}

@import 'nav';
@import 'modal';
@import 'auth';
@import 'auctions';
@import 'auction';
@import 'sell';
@import 'purchase-history';
@import 'user-summary';
@import 'query';
@import 'ledger';
@import 'beed';
