.ledger {
  .ledger-header {
    padding: 10px 0;
    border-bottom: 1px solid #ababab;
  }

  .ledger-content {
    margin: auto;
    max-width: 800px;
    padding-bottom: 20px;
    padding-top: 20px;

    .qr-container {
      background-color: $green;
      border-radius: 15px;
      padding: 15px;
    }

    .transaction-ledger-table {
      width: 100%;

      thead td { font-weight: 700; }
      td {
        padding-bottom: 20px;
        vertical-align: top;
      }
    }

  }
}
