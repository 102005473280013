.user-summary,
.user-selling,
.user-wallet,
.download-and-print {
  padding-bottom: 20px;
  padding-top: 20px;

  aside.user-summary-aside {
    margin-bottom: 30px;
    margin-right: 20px;
    width: 200px;

    p a {
      display: inline-block;
      padding: 10px;
      width: 180px;

      &.active {
        background-color: #eee;
      }
    }
  }

  .user-summary-main,
  .user-selling-main,
  .user-wallet-main {
    flex: 1 1;
    padding: 0 15px;

    @media only screen and (max-width: $bp-lg) {
      padding: 0;
    }

    & > div {
      &:not(:last-child) {
        margin-bottom: 20px;
        border-bottom: 1px solid #303030;
      }

      & > p { margin-bottom: 20px; }
    }
  }

  .wallet-item-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(4, 1fr);
  }
}
