@import './fonts';
@import './variables';

html, body, header, nav, h1, a, ul, li,
strong, main, section, img, div, h2, h3, h5, h6, p,
form, fieldset, label, input, textarea, select,
button, article, footer, small, h4 {
  border-radius: 0;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font: inherit;
  box-sizing: inherit;
  text-align: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
  color: inherit;
  background: transparent;
}

body {
  margin: 0;
  font-family: 'Dobra', 'Roboto', 'Ariel', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.container {
  margin: auto;
  max-width: 1170px;
  padding: 0 15px;
}

.flex-container {
  display: flex;
}

.flex-container-md {
  display: flex;
  @media only screen and (max-width: $bp-md) { display: block; }
}

.flex-container-lg {
  display: flex;
  @media only screen and (max-width: $bp-lg) { display: block; }
}

.flex-centered {
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.flex-1 {
  flex: 1 1;
}

.justify-between {
  justify-content: space-between;
}

h1 {
  font-size: 42px;
  font-weight: 700;
  line-height: 55px;
  margin-bottom: 10px;
}

h2 {
  font-size: 35px;
  font-weight: 700;
  line-height: 46px;
}

h3 {
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
}

h4 {
  font-size: 21px;
  line-height: 24px;
}

p,
span,
td {
  font-size: 18px;
  line-height: 24px;
}

.txt-sm {
  font-size: 16px;
  line-height: 18px;
}

.txt-center { text-align: center; }
.txt-left { text-align: left; }
.txt-right { text-align: right; }
.txt-underline { text-decoration: underline; }
.txt-bold { font-weight: 700; }
.txt-reg { font-weight: 400; }
.txt-italic { font-style: italic; }

.link-with-underline {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.link-with-permanent-underline {
  cursor: pointer;
  text-decoration: underline;
}

.form-input-container {

}

label {
  font-size: 18px;
  font-weight: 700;
  display: block;
  line-height: 24px;
}

input,
textarea,
select {
  background-color: $white;
  border: 1px solid #ddd;
  color: #000;
  font-size: 16px;
  line-height: 20px;
  padding: 10px;
  text-align: left;

  &.disabled { background-color: #ddd; }
}

textarea { resize: vertical; }

input[type="submit"].disabled,
button.disabled {
  background-color: #707070 !important;
  border-color: #707070 !important;
  cursor: default !important;
  pointer-events: none !important;
}

sup {
  font-size: 25%;
  line-height: 0;
  position: relative;
  top: -2em;
  vertical-align: baseline;
}

.btn,
.btn-link {
  background-color: $blue;
  border: 1px solid $blue;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  padding: 6px 30px;
}

.btn-reverse {
  background-color: $white;
  color: $gold;
}

.btn-clear {
  background-color: transparent;
  color: $white;
  border-color: $white;

  &:hover {
    background-color: $white;
    color: $black;
    border-color: $black;
  }
}

.btn-bg {
  border-radius: 6px;
  font-size: 23px;
  padding: 8px 40px;
  &:hover { text-decoration: none !important; }
}

.btn-sm {
  border-radius: 3px;
  font-size: 16px;
  padding: 0 6px;
  &:hover { text-decoration: none !important; }
}

.ong-txt { color: $orange !important; }
.white-txt { color: $white !important; }
.blk-txt { color: $black !important; }
.red-txt { color: $red !important; }
.grey-txt { color: #888 !important; }
.green-txt { color: $green !important; }


@media only screen and (max-width: $bp-sm) {
  h1 { font-size: 34px; line-height: 42px; }
  h2 { font-size: 22px; line-height: 28px; }
  h3 { font-size: 18px; line-height: 24px; }
  p { font-size: 16px; line-height: 20px; }
  .txt-sm { font-size: 12px; line-height: 14px; }
}

.mt-5 { margin-top: 10px !important; }
.mt-10 { margin-top: 10px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px !important; }
.mr-5 { margin-right: 5px !important; }
.mr-10 { margin-right: 10px !important; }
.mr-15 { margin-right: 15px !important; }
.mr-20 { margin-right: 20px !important; }
.mr-30 { margin-right: 30px !important; }
.mr-40 { margin-right: 40px !important; }
.ml-5 { margin-left: 5px !important; }
.ml-10 { margin-left: 10px !important; }
.ml-20 { margin-left: 20px !important; }
.ml-40 { margin-left: 40px !important; }
.mb-0 { margin-bottom: 0px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-15 { margin-bottom: 15px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-30 { margin-bottom: 30px !important; }
.pb-0 { padding-bottom: 0px !important; }
.pr-10 { padding-right: 10px !important; }
.pr-20 { padding-right: 20px !important; }


@import './app';
