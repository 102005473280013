nav {
  border-bottom: 1px solid #ababab;

  .flex-container {
    align-items: center;
    height: 50px;
    justify-content: space-between;
  }

  .nav-profile {
    cursor: pointer;
    padding: 5px 10px;
    position: relative;

    .profile-dropdown {
      background-color: #fff;
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
      position: absolute;
      right: 0px;
      transition: visibility 0s linear;
      top: 54px;
      visibility: hidden;
      width: 160px;
      z-index: 999;

      a {
        display: block;
        line-height: 24px;
        padding: 10px;
        &:hover { background-color: #eee; }
      }
    }

    &:hover {
      background-color: #eee;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      .profile-dropdown {
        // transition-delay: 0.1s;
        visibility: visible;
      }
    }
  }
}
