.sell-page {

  .sell-page-content {
    margin: auto;
    max-width: 800px;
    padding: 20px 0;

    form {

      .form-input-container {
        margin-bottom: 20px;
      }

      input,
      textarea {
        margin-top: 5px;
        width: calc(100% - 22px);
      }

      .auction-img-container {
        padding-bottom: 40%;
        width: 40%;
      }
    }
  }

  .update-photo-modal {
    input { width: calc(100% - 22px); }
  }
}
