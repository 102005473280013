.query-section {
  margin-bottom: 30px;

  form,
  .div-form {
    display: flex;

    @media only screen and (max-width: $bp-sm) {
      display: block;
    }

    > div { margin-bottom: 10px; }

    > *:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      overflow-x: hidden;
    }

    > *:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      overflow: hidden;
    }
  }

  .select-wrapper {
    background-color: #eee;
    display: inline-block;
    height: 44px;
    position: relative;

    select {
      appearance: none;
      background: none;
      border-right: 1px solid #ababab;
      height: 100%;
      padding: 0 24px 0 12px;
      position: relative;
      text-align: center;
      z-index: 2;
    }

    &:after {
      content: '';
      border: 5px solid transparent;
      border-top: 5px solid $border-grey;
      font-size: 12px;
      right: 8px;
      position: absolute;
      top: 21px;
      z-index: 1;
    }
  }

  .input-wrapper {
    flex: 1;
    height: 44px;
    position: relative;

    input[type="text"] {
      height: 22px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;

      @media only screen and (max-width: $bp-sm) {
        width: auto;
      }
    }

    .clear-input {
      align-items: center;
      bottom: 0;
      color: $border-grey;
      display: none;
      fill: currentColor;
      justify-content: center;
      right: 10px;
      position: absolute;
      top: 0;

      svg {
        cursor: pointer;
        height: 24px;
        width: 24px;
      }
    }

    &.active {
      input[type="text"] {
        padding-right: 27px;
      }

      .clear-input { display: flex; }
    }
  }

  .dropdown-input {
    ul {
      background-color: $white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      list-style: none;
      overflow: hidden;
      position: absolute;
      top: 44px;
      width: 100%;
      z-index: 2;

      li {
        pointer-events: none;

        a {
          display: block;
          overflow: hidden;
          padding: 7px 12px;
          text-align: left;
          text-overflow: ellipsis;
        }

        &.active {
          cursor: pointer;
          pointer-events: auto;

          &:hover {
            background-color: #EEE;
          }
        }
      }
    }
  }

  .button-wrapper {
    position: relative;

    button {
      border: none;
      border-radius: 0;
      cursor: pointer;
      height: 20px;
      padding: 12px 18px;
      width: 20px;

      svg {
        fill: $black;
        stroke: $black;
      }
    }
  }

  @media only screen and (max-width: $bp-sm) {
    .select-wrapper,
    .button-wrapper {
      display: inline-block;
      overflow: hidden;
    }

    .select-wrapper,
    .input-wrapper,
    .button-wrapper {
      border-radius: 5px;
      float: none;
    }
  }
}
