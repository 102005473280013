.modal {
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.6);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  h1, h3, p { color: $black; }

  .modal-content {
    background-color: #eee;
    border: 1px solid #888;
    border-radius: 5px;
    color: $black;
    margin: 10px auto;
    width: 1200px;
    padding: 45px 20px 20px;
    position: relative;
    text-align: center;
    width: 80%;
  }

  .close-modal {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  &.large-modal {
    .modal-content { max-width: 1000px; }
  }

  &.medium-modal {
    .modal-content { max-width: 800px; }
  }

  &.small-modal {
    .modal-content { max-width: 600px; }
  }

  &.xs-modal {
    .modal-content { max-width: 450px; }
  }

  &.clear-modal {
    background-color: rgba(0,0,0,0.95);
    .modal-content {
      background-color: transparent;
      border: none;
    }
  }
}
