.auction-page {
  padding-top: 20px;
  padding-bottom: 20px;

  .auction-img-container {
    margin-bottom: 20px;
    padding-bottom: 50%;
    width: 50%;

    @media only screen and (max-width: $bp-lg) {
      padding-bottom: 40%;
      width: 40%;
    }

    @media only screen and (max-width: $bp-md) {
      padding-bottom: 70%;
      width: 70%;
    }

    @media only screen and (max-width: $bp-sm) {
      padding-bottom: 100%;
      width: 100%;
    }
  }

  .auction-content {
    flex: 1 1;
    padding: 0 20px;

    @media only screen and (max-width: $bp-md) {
      padding: 0;
    }

    .seller-wrapper {
      border: 1px solid #303030;
      border-radius: 5px;
      display: inline-block;
      padding: 5px 10px;

      .flex-container { align-items: center; }
    }
  }

  .bid-type {
    margin-bottom: 15px;
  }

  .auction-actions {
    > div { width: 250px; }
    .btn {
      text-align: center;
      width: 100%;
    }
  }
}

.submit-bid-modal {
  form {
    text-align: left;

    .input-wrapper {
      margin-bottom: 20px;

      p { margin-bottom: 5px; }
      input {
        width: calc(100% - 22px);
      }
    }

  }
}

// .buy-it-now-modal {
//   .buy-it-now-form {
//     border-right: 1px solid #ddd;
//     margin-right: 35px;
//     padding-right: 15px;
//     width: 500px;
//
//     form {
//       text-align: left;
//
//       .input-wrapper {
//         margin-bottom: 20px;
//
//         p { margin-bottom: 5px; }
//         input {
//           width: 460px;
//         }
//         input[type="checkbox"] { width: auto; }
//       }
//
//       .input-wrapper-double {
//         input {
//           width: 214px;
//           &:first-child { margin-right: 10px; }
//         }
//       }
//
//       .input-wrapper-triple {
//         input,
//         select {
//           width: 132px;
//           &:first-child { margin-right: 10px; }
//           &:nth-child(2) { margin-right: 10px; }
//         }
//       }
//
//     }
//   }
//
//   .buy-it-now-submit {
//     padding: 20px 0;
//     width: 450px;
//
//     .divide-line {
//       border-bottom: 1px solid #ddd;
//       margin-bottom: 20px;
//     }
//   }
// }

.bid-history-modal {
  .auction-header-info {
    margin: auto;
    max-width: 400px;
  }

  table {
    border: 1px solid #ddd;
    border-collapse: collapse;
    width: 100%;

    thead {
      background-color: $gold;
      color: $white;
    }

    tr {
      &:nth-child(2n) {
        background-color: $white;
      }
    }

    td {
      padding: 10px;
      text-align: left;

      &:first-child {
        max-width: 300px;
        overflow-x: auto;
      }
    }
  }
}

.buy-it-now {
  padding-bottom: 20px;
  padding-top: 20px;

  .buy-it-now-info {
    border: 1px solid #303030;
    border-radius: 5px;
    padding: 15px;
  }

  .buy-it-now-address {
    border: 1px solid #303030;
    border-radius: 5px;
    padding: 15px;
    text-align: left;

    .input-wrapper {
      margin-bottom: 20px;

      p { margin-bottom: 5px; }
      input {
        // width: 460px;
      }
      input[type="checkbox"] { width: auto; }
    }

    .input-wrapper-double {
      input {
        // width: 214px;
        width: calc(50% - 27px);
        &:first-child { margin-right: 10px; }
      }
    }

    .input-wrapper-triple {
      input,
      select {
        // width: 132px;
        width: calc(33.33% - 29px);
        &:first-child { margin-right: 10px; }
        &:nth-child(2) { margin-right: 10px; }
      }
    }
  }

  .buy-it-now-payment-form {
    width: 350px;

    .buy-it-now-payment-form-container {
      border: 1px solid #303030;
      border-radius: 5px;
      padding: 15px;

      .total-price {
        border-top: 1px solid #303030;
        padding-top: 10px;
      }
    }
  }
}
