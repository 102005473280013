@font-face {
  font-family: 'Dobra';
  src: url('fonts/Dobra-Book.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dobra';
  src: url('fonts/Dobra-Bold.otf');
  font-weight: 700;
  font-style: normal;
}
