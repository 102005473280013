.dl-print-content {
  margin: auto;
  max-width: 800px;
  padding-bottom: 20px;
  padding-top: 20px;

  .qr-container {
    background-color: $green;
    border-radius: 20px;
    padding: 25px;
  }
}
