.authentication.sign-in,
.authentication.sign-up {
  margin: auto;
  max-width: 400px;
  padding-bottom: 20px;
  padding-top: 20px;

  form {

    .form-input-container {
      margin-bottom: 20px;
    }

    input,
    textarea {
      margin-top: 5px;
      width: calc(100% - 22px);
    }
  }

  .edit-user-field {
    align-items: center;
    div:first-child { flex: 1 1; }
  }

  .user-img-container {
    width: 30%;
    padding-bottom: 30%;
  }
}
